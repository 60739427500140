var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5"},[_c('v-breadcrumbs',{attrs:{"divider":">","items":[
      { text: 'ΠΔΕ' },
      {
        text: 'ΕΡΓΑ',
        exact: true,
        to: '/pde/projects',
      },
      {
        text: this.pdeJobTitle,
        exact: true,
        to: ("/pde/projects/" + (this.$route.params.jobId)),
      },
      {
        text: this.subJobTitle,
      } ]}}),_c('v-card',[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" ΣΤΟΙΧΕΙΑ ΥΠΟΕΡΓΟΥ ")]),_c('v-tab',[_vm._v(" ΕΡΓΟΛΑΒΙΚΗ ΣΥΜΒΑΣΗ ")]),_c('v-tab',[_vm._v(" ΛΟΓΑΡΙΑΣΜΟΙ ")]),_c('v-tab',[_vm._v(" ΕΝΤΟΛΕΣ ΠΛΗΡΩΜΩΝ ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('sub-job-edit',{on:{"setSubJobTitle":function (ref) {
        var title = ref.title;

        return (_vm.subJobTitle = title);
}}})],1),_c('v-tab-item',[_c('constructor-contracts-view')],1),_c('v-tab-item',[_c('accounts-view')],1),_c('v-tab-item',[_c('constructor-contract-payments-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }