<template>
  <v-form @submit.prevent="submit" class="pa-4">
    <v-progress-linear
      v-if="!form.title"
      rounded
      indeterminate
      height="2"
      class="mb-4"
      color="#555555"
    ></v-progress-linear>

    <v-col class="text-caption font-weight-light">
      ( * ) <v-icon small right>mdi-arrow-right</v-icon> Υποχρεωτικό Πεδίο
    </v-col>

    <v-text-field label="Τίτλος *" v-model="form.title"></v-text-field>

    <v-text-field label="Κωδικός *" v-model="form.subPdeJobCode"></v-text-field>

    <v-text-field
      type="number"
      label="Προϋπολογισμός"
      v-model="form.budget"
    ></v-text-field>

    <v-autocomplete
      clearable
      item-value="id"
      item-text="name"
      label="Φάση υποέργου *"
      v-model="form.jobPhaseId"
      :items="jobPhasesList"
      :loading="jobPhasesList.length == 0"
    ></v-autocomplete>

    <v-autocomplete
      clearable
      item-value="id"
      item-text="name"
      label="Φάση υποέργου αρχής έτους"
      v-model="form.newYearJobPhaseId"
      :items="jobPhasesList"
      :loading="jobPhasesList.length == 0"
    ></v-autocomplete>

    <v-autocomplete
      clearable
      item-value="id"
      item-text="name"
      label="Τύπος υποέργου *"
      v-model="form.jobTypeId"
      :items="jobTypesList"
      :loading="jobPhasesList.length == 0"
    ></v-autocomplete>

    <v-text-field
      label="Υπεύθυνος"
      v-model="form.contactDetails"
    ></v-text-field>

    <v-textarea rows="2" label="Σχόλια" v-model="form.comments"></v-textarea>

    <v-row justify="center" class="py-4">
      <v-btn
        rounded
        type="submit"
        class="primary darken-2"
        :loading="submitLoader"
      >
        Αποθηκευση
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import { required, decimal } from "vuelidate/lib/validators";

export default {
  validations: {
    form: {
      title: { required },
      budget: { required },
    },
  },
  data() {
    return {
      form: {
        title: null,
        budget: null,
        jobTypeId: null,
        jobPhaseId: null,
        newYearJobPhaseId: null,
        contactDetails: null,
        subPdeJobCode: null,
        pdeJobId: this.$route.params.jobId,
      },
      jobPhasesList: [],
      jobTypesList: [],
      submitLoader: false,
    };
  },
  methods: {
    async submit() {
      this.submitLoader = true;
      try {
        // TODO: validations
        await axios.put(
          `/api/pde/sub/job/edit/${this.$route.params.subJobId}`,
          { ...this.form }
        );

        // show success message
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Το υποέργο ενημερώθηκε με επιτυχία",
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
  },
  async created() {
    try {
      // get subJob data
      let res = await axios(`/api/pde/sub/job/${this.$route.params.subJobId}`);
      this.form.title = res.data.data.title;
      this.form.subPdeJobCode = res.data.data.subPdeJobCode;
      this.form.budget = res.data.data.budget;
      this.form.jobPhaseId = res.data.data.jobPhase.id;
      this.form.jobTypeId = res.data.data.jobType.id;
      this.form.newYearJobPhaseId = res.data.data.newYearJobPhase.id;
      this.form.contactDetails = res.data.data.contactDetails;
      this.form.comments = res.data.data.comments;

      // set subJob title
      this.$emit("setSubJobTitle", { title: this.form.title });

      // get phases and types lists
      let phases = await axios("/api/job/phase/list");
      let types = await axios("/api/job/type/list");
      this.jobPhasesList = phases.data.data;
      this.jobTypesList = types.data.data;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss" scoped></style>
