var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2 ml-3",attrs:{"tile":"","text":"","small":"","outlined":"","color":"primary darken-2"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Προσθηκη Λογαριασμού ")],1)]}}]),model:{value:(_vm.addAccountDialog),callback:function ($$v) {_vm.addAccountDialog=$$v},expression:"addAccountDialog"}},[_c('account-add',{on:{"closeDialog":function($event){_vm.addAccountDialog = false},"refreshAccount":_vm.getAccountsList}})],1),_c('v-data-table',{attrs:{"no-data-text":"Δεν Βρέθηκαν Λογαριασμοί","headers":_vm.headers,"items":_vm.accountsList,"loading":_vm.accountsLoader},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"600","close-on-click":false,"value":_vm.accountEditForm == item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [(item.year == _vm.account[0].year)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.accountEditForm = item}}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_vm._v(" Επεξεργασία ")]):_vm._e()]}}],null,true)},[_c('account-edit',{attrs:{"edit-form":_vm.accountEditForm},on:{"closeDialog":function($event){_vm.accountEditForm = null},"refreshAccount":_vm.getAccountsList}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }