var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Προσθήκη Λογαριασμού ")]),_c('v-divider'),_c('v-card-actions',[_c('v-form',{staticClass:"flex-1",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Ποσό λογαριασμού *"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('v-text-field',{attrs:{"readonly":"","label":"ΠΤΑΑ ποσό λογαριασμού"},model:{value:(_vm.form.restAmount),callback:function ($$v) {_vm.$set(_vm.form, "restAmount", $$v)},expression:"form.restAmount"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Αρ. πρωτοκόλλου ΔΙΑΠ"},model:{value:(_vm.form.diapProtocolNumber),callback:function ($$v) {_vm.$set(_vm.form, "diapProtocolNumber", $$v)},expression:"form.diapProtocolNumber"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","persistent-hint":"","hint":"ΗΗ/ΜΜ/ΕΕΕΕ","append-icon":"mdi-calendar","label":"Ημ/νία πρωτοκόλλου ΔΙΑΠ","value":_vm.formatDate(_vm.form.diapProtocolNumberDate)}},on))]}}]),model:{value:(_vm.diapProtocolNumberDateMenu),callback:function ($$v) {_vm.diapProtocolNumberDateMenu=$$v},expression:"diapProtocolNumberDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.diapProtocolNumberDateMenu = false}},model:{value:(_vm.form.diapProtocolNumberDate),callback:function ($$v) {_vm.$set(_vm.form, "diapProtocolNumberDate", $$v)},expression:"form.diapProtocolNumberDate"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Αρ. πρωτοκόλλου ΠΤΑΑ"},model:{value:(_vm.form.ptaaProtocolNumber),callback:function ($$v) {_vm.$set(_vm.form, "ptaaProtocolNumber", $$v)},expression:"form.ptaaProtocolNumber"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","persistent-hint":"","hint":"ΗΗ/ΜΜ/ΕΕΕΕ","append-icon":"mdi-calendar","label":"Ημ/νία πρωτοκόλλου ΠΤΑΑ","value":_vm.formatDate(_vm.form.ptaaProtocolNumberDate)}},on))]}}]),model:{value:(_vm.ptaaProtocolNumberDateMenu),callback:function ($$v) {_vm.ptaaProtocolNumberDateMenu=$$v},expression:"ptaaProtocolNumberDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.ptaaProtocolNumberDateMenu = false}},model:{value:(_vm.form.ptaaProtocolNumberDate),callback:function ($$v) {_vm.$set(_vm.form, "ptaaProtocolNumberDate", $$v)},expression:"form.ptaaProtocolNumberDate"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Αριθμός πρωτοκόλλου σύμφωνης γνώμης"},model:{value:(_vm.form.agreementProtocolNumber),callback:function ($$v) {_vm.$set(_vm.form, "agreementProtocolNumber", $$v)},expression:"form.agreementProtocolNumber"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","persistent-hint":"","hint":"ΗΗ/ΜΜ/ΕΕΕΕ","append-icon":"mdi-calendar","label":"Ημ/νία πρωτοκόλλου σύμφωνης γνώμης","value":_vm.formatDate(_vm.form.agreementProtocolNumberDate)}},on))]}}]),model:{value:(_vm.agreementProtocolNumberDateMenu),callback:function ($$v) {_vm.agreementProtocolNumberDateMenu=$$v},expression:"agreementProtocolNumberDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.agreementProtocolNumberDateMenu = false}},model:{value:(_vm.form.agreementProtocolNumberDate),callback:function ($$v) {_vm.$set(_vm.form, "agreementProtocolNumberDate", $$v)},expression:"form.agreementProtocolNumberDate"}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{staticClass:"mr-2",attrs:{"multiple":"","clearable":"","label":"Προσθήκη Συνημμένων Αρχείων"},model:{value:(_vm.attachmentsToUpload),callback:function ($$v) {_vm.attachmentsToUpload=$$v},expression:"attachmentsToUpload"}})],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","small":"","outlined":"","disabled":_vm.attachmentsToUpload.length == 0,"loading":_vm.uploadLoader},on:{"click":_vm.onUploadAttachments}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Αρχειοθετηση ")],1)],1)],1),(_vm.uploadedAttachments.length > 0)?_c('v-data-table',{staticClass:"ml-8 mb-2",attrs:{"hide-default-header":"","hide-default-footer":"","headers":[
          { value: 'filename' },
          { value: 'options', cellClass: 'text-end' } ],"items":_vm.uploadedAttachments},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","loading":_vm.deleteLoader == item.id},on:{"click":function($event){return _vm.onDeleteAttachment(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"footer",fn:function(){return [_c('v-divider')]},proxy:true}],null,true)}):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.resetForm();
          _vm.$emit('closeDialog');}}},[_vm._v(" Ακυρωση ")]),_c('v-btn',{attrs:{"text":"","type":"submit","color":"primary darken-2","loading":_vm.submitLoader}},[_vm._v(" Αποθηκευση ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }