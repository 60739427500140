<template>
  <v-card>
    <v-card-title> Προσθήκη Λογαριασμού </v-card-title>

    <v-divider></v-divider>

    <v-card-actions>
      <v-form class="flex-1" @submit.prevent="submit">
        <v-text-field
          label="Ποσό λογαριασμού *"
          v-model="form.amount"
        ></v-text-field>

        <v-text-field
          readonly
          label="ΠΤΑΑ ποσό λογαριασμού"
          v-model="form.restAmount"
        ></v-text-field>

        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Αρ. πρωτοκόλλου ΔΙΑΠ"
              v-model="form.diapProtocolNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-menu
              offset-y
              min-width="auto"
              v-model="diapProtocolNumberDateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-on="on"
                  readonly
                  persistent-hint
                  hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                  append-icon="mdi-calendar"
                  label="Ημ/νία πρωτοκόλλου ΔΙΑΠ"
                  :value="formatDate(form.diapProtocolNumberDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.diapProtocolNumberDate"
                @input="diapProtocolNumberDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Αρ. πρωτοκόλλου ΠΤΑΑ"
              v-model="form.ptaaProtocolNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-menu
              offset-y
              min-width="auto"
              v-model="ptaaProtocolNumberDateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-on="on"
                  readonly
                  persistent-hint
                  hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                  append-icon="mdi-calendar"
                  label="Ημ/νία πρωτοκόλλου ΠΤΑΑ"
                  :value="formatDate(form.ptaaProtocolNumberDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.ptaaProtocolNumberDate"
                @input="ptaaProtocolNumberDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Αριθμός πρωτοκόλλου σύμφωνης γνώμης"
              v-model="form.agreementProtocolNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-menu
              offset-y
              min-width="auto"
              v-model="agreementProtocolNumberDateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-on="on"
                  readonly
                  persistent-hint
                  hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                  append-icon="mdi-calendar"
                  label="Ημ/νία πρωτοκόλλου σύμφωνης γνώμης"
                  :value="formatDate(form.agreementProtocolNumberDate)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.agreementProtocolNumberDate"
                @input="agreementProtocolNumberDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="8">
            <v-file-input
              multiple
              clearable
              class="mr-2"
              label="Προσθήκη Συνημμένων Αρχείων"
              v-model="attachmentsToUpload"
            >
            </v-file-input>
          </v-col>

          <v-col cols="4" align-self="center">
            <v-btn
              text
              small
              outlined
              :disabled="attachmentsToUpload.length == 0"
              :loading="uploadLoader"
              @click="onUploadAttachments"
            >
              <v-icon left>mdi-upload</v-icon>
              Αρχειοθετηση
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          v-if="uploadedAttachments.length > 0"
          class="ml-8 mb-2"
          hide-default-header
          hide-default-footer
          :headers="[
            { value: 'filename' },
            { value: 'options', cellClass: 'text-end' },
          ]"
          :items="uploadedAttachments"
        >
          <template #[`item.options`]="{ item }">
            <v-btn
              icon
              class="ml-auto"
              :loading="deleteLoader == item.id"
              @click="onDeleteAttachment(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template #footer>
            <v-divider></v-divider>
          </template>
        </v-data-table>

        <v-btn
          text
          @click="
            resetForm();
            $emit('closeDialog');
          "
        >
          Ακυρωση
        </v-btn>

        <v-btn
          text
          type="submit"
          color="primary darken-2"
          :loading="submitLoader"
        >
          Αποθηκευση
        </v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        amount: null,
        ptaaAmount: null,
        diapProtocolNumber: null,
        diapProtocolNumberDate: null,
        ptaaProtocolNumber: null,
        ptaaProtocolNumberDate: null,
        agreementProtocolNumber: null,
        agreementProtocolNumberDate: null,
        pdeSubJobAccountAttachments: [],
      },
      diapProtocolNumberDateMenu: false,
      ptaaProtocolNumberDateMenu: false,
      agreementProtocolNumberDateMenu: false,
      uploadedAttachments: [],
      attachmentsToUpload: [],
      uploadLoader: false,
      deleteLoader: false,
      submitLoader: false,
    };
  },
  methods: {
    ...mapActions(["uploadFiles", "removeFile"]),
    async submit() {},
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    resetForm() {
      this.form = {};
    },
    async onUploadAttachments() {
      this.uploadLoader = true;
      try {
        // Create form data
        let formData = new FormData();
        this.attachmentsToUpload.forEach((file) => {
          formData.append("files", file, file.name);
        });

        // upload files
        let res = await this.uploadFiles(formData);

        // save file ids
        res.data.data.forEach((item) => {
          this.uploadedAttachments.push(item);
        });
        this.attachmentsToUpload = [];
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
    async onDeleteAttachment(id) {
      this.deleteLoader = id;
      try {
        await axios.delete(`/api/repository/file/remove/${id}`, {
          data: {
            pdeJobId: this.$route.params.jobId,
          },
        });
        this.uploadedAttachments = this.uploadedAttachments.filter(
          (el) => el.id != id
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
