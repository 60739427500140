<template>
  <v-form @submit.prevent="submit" class="pa-4">
    <v-progress-linear
      v-if="!form.id"
      rounded
      indeterminate
      height="2"
      class="mb-4"
      color="#555555"
    ></v-progress-linear>
    <v-row>
      <v-col cols="6">
        <v-menu
          offset-y
          min-width="auto"
          v-model="startDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              readonly
              persistent-hint
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              label="Ημερομηνία έναρξης *"
              append-icon="mdi-calendar"
              :value="formatDate(form.startDate)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.startDate"
            @input="startDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6">
        <v-menu
          offset-y
          min-width="auto"
          v-model="endDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              readonly
              persistent-hint
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              label="Ημερομηνία λήξης *"
              append-icon="mdi-calendar"
              :value="formatDate(form.endDate)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.endDate"
            @input="endDateMenu = false"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Ποσό εργολαβικής σύμβασης *"
          v-model="form.amount"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          readonly
          label="Υπόλοιπο ποσό εργολαβικής σύμβασης"
          v-model="form.restAmount"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Τελευταίος ΑΠΕ"
          v-model="form.lastApe"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          label="Μέση έκπτωση"
          v-model="form.meanDiscount"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          label="ΑΔΑΜ σύμβασης"
          v-model="form.adamContract"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Ανάδοχος" v-model="form.contractor"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
          readonly
          label="Συμβατικό αντικείμενο"
          v-model="form.conventionalObject"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-checkbox
          label="Τελικός μηδενικός λογαριασμός"
          v-model="form.finalZeroAccount"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="my-8"></v-divider>

    <v-row>
      <v-col cols="4" class="pr-6">
        <div class="text-body-1 text-center font-weight-medium mb-2">
          Προσωρινή παραλαβή
        </div>

        <v-text-field
          label="Αριθμός πρωτοκόλλου"
          v-model="form.tempDelivery.protocolNumber"
        ></v-text-field>

        <v-menu
          offset-y
          min-width="auto"
          v-model="tempDeliveryDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              readonly
              persistent-hint
              class="mb-6"
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              append-icon="mdi-calendar"
              label="Ημερομηνία πρωτοκόλλου"
              :value="formatDate(form.tempDelivery.protocolNumberDate)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.tempDelivery.protocolNumberDate"
            @input="tempDeliveryNumberDateMenu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-menu offset-y min-width="500px" :close-on-content-click="false">
          <template #activator="{ on: menu }">
            <v-btn small v-on="{ ...menu }">
              συνημμενα αρχεια
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <v-data-table
              dense
              hide-default-header
              hide-default-footer
              no-data-text="δεν υπάρχουν αρχεία"
              :headers="[
                { value: 'filename' },
                { value: 'options', cellClass: 'text-end' },
              ]"
              :items="[
                ...attachments.tempDelivery.uploaded,
                ...form.tempDelivery.attachmentsIds,
              ]"
            >
              <template #[`item.options`]="{ item }">
                <v-btn
                  icon
                  small
                  class="mr-1"
                  :loading="downloadLoader == item.id"
                  @click="onDownloadAttachment(item)"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  :loading="deleteLoader == item.id"
                  @click="onDeleteAttachment(item.id)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>

              <template #footer>
                <v-divider class="mb-2"></v-divider>
                <v-row dense>
                  <v-col cols="8">
                    <v-file-input
                      multiple
                      clearable
                      class="mr-2"
                      label="Προσθήκη Συνημμένων Αρχείων"
                      v-model="attachments.tempDelivery.toUpload"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="3" align-self="center">
                    <v-btn
                      text
                      small
                      outlined
                      :disabled="attachments.tempDelivery.toUpload.length == 0"
                      :loading="uploadLoader"
                      @click="onUploadAttachments('tempDelivery')"
                    >
                      <v-icon left>mdi-upload</v-icon>
                      Αρχειοθετηση
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-menu>
      </v-col>

      <v-col cols="4">
        <div class="text-body-1 text-center font-weight-medium mb-2">
          Οριστική παραλαβή
        </div>

        <v-text-field
          label="Αριθμός πρωτοκόλλου"
          v-model="form.finalDelivery.protocolNumber"
        ></v-text-field>

        <v-menu
          offset-y
          min-width="auto"
          v-model="finalDeliveryDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              readonly
              persistent-hint
              class="mb-6"
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              append-icon="mdi-calendar"
              label="Ημερομηνία πρωτοκόλλου"
              :value="formatDate(form.finalDelivery.protocolNumberDate)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.finalDelivery.protocolNumberDate"
            @input="finalDeliveryNumberDateMenu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-menu offset-y min-width="500px" :close-on-content-click="false">
          <template #activator="{ on: menu }">
            <v-btn small v-on="{ ...menu }">
              συνημμενα αρχεια
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <v-data-table
              dense
              hide-default-header
              hide-default-footer
              no-data-text="δεν υπάρχουν αρχεία"
              :headers="[
                { value: 'filename' },
                { value: 'options', cellClass: 'text-end' },
              ]"
              :items="[
                ...attachments.finalDelivery.uploaded,
                ...form.finalDelivery.attachmentsIds,
              ]"
            >
              <template #[`item.options`]="{ item }">
                <v-btn
                  icon
                  small
                  class="mr-1"
                  :loading="downloadLoader == item.id"
                  @click="onDownloadAttachment(item)"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  :loading="deleteLoader == item.id"
                  @click="onDeleteAttachment(item.id)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>

              <template #footer>
                <v-divider class="mb-2"></v-divider>
                <v-row dense>
                  <v-col cols="8">
                    <v-file-input
                      multiple
                      clearable
                      class="mr-2"
                      label="Προσθήκη Συνημμένων Αρχείων"
                      v-model="attachments.finalDelivery.toUpload"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="3" align-self="center">
                    <v-btn
                      text
                      small
                      outlined
                      :disabled="attachments.finalDelivery.toUpload.length == 0"
                      :loading="uploadLoader"
                      @click="onUploadAttachments('finalDelivery')"
                    >
                      <v-icon left>mdi-upload</v-icon>
                      Αρχειοθετηση
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-menu>
      </v-col>

      <v-col cols="4">
        <div class="text-body-1 text-center font-weight-medium mb-2">
          Βεβαίωση περαίωσης
        </div>

        <v-text-field
          label="Αριθμός πρωτοκόλλου"
          v-model="form.completionCertificate.protocolNumber"
        ></v-text-field>

        <v-menu
          offset-y
          min-width="auto"
          v-model="completionCertificateDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              readonly
              persistent-hint
              class="mb-6"
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              append-icon="mdi-calendar"
              label="Ημερομηνία πρωτοκόλλου"
              :value="formatDate(form.completionCertificate.protocolNumberDate)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.completionCertificate.protocolNumberDate"
            @input="completionCertificateNumberDateMenu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-menu offset-y min-width="500px" :close-on-content-click="false">
          <template #activator="{ on: menu }">
            <v-btn small v-on="{ ...menu }">
              συνημμενα αρχεια
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <v-data-table
              dense
              hide-default-header
              hide-default-footer
              no-data-text="δεν υπάρχουν αρχεία"
              :headers="[
                { value: 'filename' },
                { value: 'options', cellClass: 'text-end' },
              ]"
              :items="[
                ...attachments.completionCertificate.uploaded,
                ...form.completionCertificate.attachmentsIds,
              ]"
            >
              <template #[`item.options`]="{ item }">
                <v-btn
                  icon
                  small
                  class="mr-1"
                  :loading="downloadLoader == item.id"
                  @click="onDownloadAttachment(item)"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  :loading="deleteLoader == item.id"
                  @click="onDeleteAttachment(item.id)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>

              <template #footer>
                <v-divider class="mb-2"></v-divider>
                <v-row dense>
                  <v-col cols="8">
                    <v-file-input
                      multiple
                      clearable
                      class="mr-2"
                      label="Προσθήκη Συνημμένων Αρχείων"
                      v-model="attachments.completionCertificate.toUpload"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="3" align-self="center">
                    <v-btn
                      text
                      small
                      outlined
                      :disabled="
                        attachments.completionCertificate.toUpload.length == 0
                      "
                      :loading="uploadLoader"
                      @click="onUploadAttachments('completionCertificate')"
                    >
                      <v-icon left>mdi-upload</v-icon>
                      Αρχειοθετηση
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider class="my-8"></v-divider>

    <v-row justify="center">
      <v-col cols="8">
        <v-data-table
          dense
          hide-default-header
          hide-default-footer
          no-data-text="δεν υπάρχουν αρχεία"
          :headers="[
            { value: 'filename' },
            { value: 'options', cellClass: 'text-end' },
          ]"
          :items="[
            ...attachments.subJobConstructorContract.uploaded,
            ...form.pdeSubJobConstructorContractAttachments,
          ]"
        >
          <template #top>
            <span class="text-overline"
              >Συνημμενα Αρχεια Εργολαβικης Συμβασης</span
            >
            <v-divider></v-divider>
          </template>

          <template #[`item.options`]="{ item }">
            <v-btn
              icon
              small
              class="mr-1"
              :loading="downloadLoader == item.id"
              @click="onDownloadAttachment(item)"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>

            <v-btn
              icon
              small
              :loading="deleteLoader == item.id"
              @click="onDeleteAttachment(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template #footer>
            <v-divider class="mb-2"></v-divider>
            <v-row dense>
              <v-col cols="8">
                <v-file-input
                  multiple
                  clearable
                  class="mr-2"
                  label="Προσθήκη Συνημμένων Αρχείων"
                  v-model="attachments.subJobConstructorContract.toUpload"
                >
                </v-file-input>
              </v-col>

              <v-col cols="3" align-self="center">
                <v-btn
                  text
                  small
                  outlined
                  :disabled="
                    attachments.subJobConstructorContract.toUpload.length == 0
                  "
                  :loading="uploadLoader"
                  @click="onUploadAttachments('subJobConstructorContract')"
                >
                  <v-icon left>mdi-upload</v-icon>
                  Αρχειοθετηση
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-divider class="my-8"></v-divider>

    <v-row justify="center" class="pb-4">
      <v-btn
        rounded
        type="submit"
        class="primary darken-2"
        :loading="submitLoader"
      >
        Αποθηκευση
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      contractLoader: false,
      form: {
        id: null,
        adamContract: null,
        amount: null,
        contractor: null,
        conventionalObject: null,
        endDate: null,
        finalZeroAccount: null,
        id: null,
        lastApe: null,
        meanDiscount: null,
        tempDelivery: {
          attachmentsIds: [],
          protocolNumber: null,
          protocolNumberDate: null,
        },
        finalDelivery: {
          attachmentsIds: [],
          protocolNumber: null,
          protocolNumberDate: null,
        },
        completionCertificate: {
          attachmentsIds: [],
          protocolNumber: null,
          protocolNumberDate: null,
        },
        pdeSubJobConstructorContractAttachments: [],
        restAmount: null,
        startDate: null,
      },
      attachments: {
        tempDelivery: {
          uploaded: [],
          toUpload: [],
        },
        finalDelivery: {
          uploaded: [],
          toUpload: [],
        },
        completionCertificate: {
          uploaded: [],
          toUpload: [],
        },
        subJobConstructorContract: {
          uploaded: [],
          toUpload: [],
        },
      },
      startDateMenu: false,
      endDateMenu: false,
      tempDeliveryDateMenu: false,
      finalDeliveryDateMenu: false,
      completionCertificateDateMenu: false,
      submitLoader: false,
      uploadLoader: false,
      downloadLoader: false,
      deleteLoader: false,
    };
  },
  methods: {
    async getConstructorContract() {
      this.contractLoader = true;
      try {
        let res = await axios(
          `/api/pde/sub/job/constructor/contract/pdesubjob/${this.$route.params.subJobId}`
        );
        this.form = { ...this.form, ...res.data.data };
      } catch (err) {
        console.log(err);
      } finally {
        this.contrastLoader = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    ...mapActions(["uploadFiles", "removeFile"]),
    async submit() {
      this.submitLoader = true;
      try {
        // TODO: check validations
        await axios.put(`/api/pde/job/edit/${this.form.id}`, { ...this.form });

        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Οι τροποποιήσεις αποθηκεύτηκαν με επιτυχία",
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    async onUploadAttachments(type) {
      this.uploadLoader = true;
      try {
        // Create form data
        let formData = new FormData();
        this.attachments[type].toUpload.forEach((file) => {
          formData.append("files", file, file.name);
        });

        // upload files
        let res = await this.uploadFiles(formData);

        // save file ids
        res.data.data.forEach((item) => {
          this.attachments[type].uploaded.push(item);
        });
        this.attachments[type].toUpload = [];
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
    async onDownloadAttachment(item) {
      this.downloadLoader = true;
      try {
        let res = await axios(`/api/repository/file/download/${item.id}`, {
          responseType: "blob",
        });
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.downloadLoader = false;
      }
    },
    // TODO
    async onDeleteAttachment(id) {
      this.deleteLoader = id;
      try {
        await axios.delete(`/api/repository/file/remove/${id}`, {
          data: {
            pdeJobId: this.$route.params.jobId,
          },
        });
        this.uploadedAttachments = this.uploadedAttachments.filter(
          (el) => el.id != id
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoader = false;
      }
    },
  },
  created() {
    this.getConstructorContract();
  },
};
</script>

<style lang="scss" scoped></style>
