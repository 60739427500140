<template>
  <div class="mx-5">
    <v-breadcrumbs
      divider=">"
      :items="[
        { text: 'ΠΔΕ' },
        {
          text: 'ΕΡΓΑ',
          exact: true,
          to: '/pde/projects',
        },
        {
          text: this.pdeJobTitle,
          exact: true,
          to: `/pde/projects/${this.$route.params.jobId}`,
        },
        {
          text: this.subJobTitle,
        },
      ]"
    >
    </v-breadcrumbs>

    <v-card>
      <v-tabs centered v-model="tab">
        <v-tab> ΣΤΟΙΧΕΙΑ ΥΠΟΕΡΓΟΥ </v-tab>

        <v-tab> ΕΡΓΟΛΑΒΙΚΗ ΣΥΜΒΑΣΗ </v-tab>

        <v-tab> ΛΟΓΑΡΙΑΣΜΟΙ </v-tab>

        <v-tab> ΕΝΤΟΛΕΣ ΠΛΗΡΩΜΩΝ </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <sub-job-edit
            @setSubJobTitle="({ title }) => (subJobTitle = title)"
          />
        </v-tab-item>

        <v-tab-item>
          <constructor-contracts-view />
        </v-tab-item>

        <v-tab-item>
          <accounts-view />
        </v-tab-item>

        <v-tab-item>
          <constructor-contract-payments-view />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import SubJobEdit from "./SubJobsViewTabs/SubJobEdit.vue";
import ConstructorContractsView from "./SubJobsViewTabs/ConstructorContractView.vue";
import AccountsView from "./SubJobsViewTabs/Accounts/AccountsView.vue";
import ConstructorContractPaymentsView from "./SubJobsViewTabs/ConstructorContractPayments/ConstructorContractPaymentsView.vue";

export default {
  components: {
    SubJobEdit,
    ConstructorContractsView,
    AccountsView,
    ConstructorContractPaymentsView
  },
  data() {
    return {
      pdeJobTitle: null,
      subJobTitle: null,
      tab: null,
    };
  },
  async created() {
    let res = await axios(`/api/pde/job/${this.$route.params.jobId}`);
    this.pdeJobTitle = res.data.data.title;
  },
};
</script>

<style lang="scss" scoped></style>
