<template>
    <v-card>

    </v-card>
</template>

<script>
    export default {
        props: {
            editForm: Object
        },
        data() {
            return {
                form: {}
            }
        },
        methods: {
            async submit() {

            },
        },
        created () {
            this.form = { ...this.editForm };
        },
    }
</script>

<style lang="scss" scoped>

</style>