<template>
  <div class="pa-5">
    <v-dialog
      persistent
      max-width="600"
      :close-on-click="false"
      v-model="addAccountDialog"
    >
      <template #activator="{ on }">
        <v-btn
          tile
          text
          small
          outlined
          color="primary darken-2"
          class="my-2 ml-3"
          v-on="on"
        >
          <v-icon left>mdi-plus</v-icon>
          Προσθηκη Λογαριασμού
        </v-btn>
      </template>

      <account-add
        @closeDialog="addAccountDialog = false"
        @refreshAccount="getAccountsList"
      />
    </v-dialog>

    <v-data-table
      no-data-text="Δεν Βρέθηκαν Λογαριασμοί"
      :headers="headers"
      :items="accountsList"
      :loading="accountsLoader"
    >
      <template #[`item.options`]="{ item }">
        <v-dialog
          persistent
          max-width="600"
          :close-on-click="false"
          :value="accountEditForm == item"
        >
          <template #activator="{ on: dialog }">
            <v-tooltip v-if="item.year == account[0].year" top>
              <template #activator="{ on: tooltip }">
                <v-btn
                  icon
                  v-on="{ ...dialog, ...tooltip }"
                  @click="accountEditForm = item"
                >
                  <v-icon small>mdi-pen</v-icon>
                </v-btn>
              </template>
              Επεξεργασία
            </v-tooltip>
          </template>

          <account-edit
            :edit-form="accountEditForm"
            @closeDialog="accountEditForm = null"
            @refreshAccount="getAccountsList"
          />
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import AccountAdd from "./AccountAdd.vue";
import AccountEdit from "./AccountEdit.vue";

export default {
  components: {
    AccountAdd,
    AccountEdit,
  },
  data() {
    return {
      accountsLoader: false,
      accountsList: [],
      headers: [
        {
          text: "Α/Α",
          value: "incrementalNumber",
          width: "80px",
        },
        {
          text: "Ποσό λογαριασμού",
          value: "amount",
        },
        {
          text: "Υπόλοιπο ποσού λογαριασμού ",
          value: "restAmount",
        },
        {
          value: "ptaaAmount",
          text: "ΠΤΑΑ ποσό λογαριασμού",
        },
        {
          value: "diapProtocolNumber",
          text: "Αριθμός πρωτοκόλλου ΔΙΑΠ",
        },
        {
          value: "diapProtocolNumberDate",
          text: "Ημερομηνία πρωτοκόλλου ΔΙΑΠ",
        },
        {
          value: "ptaaProtocolNumber",
          text: "Αριθμός πρωτοκόλλου ΠΤΑΑ",
        },
        {
          value: "ptaaProtocolNumberDate",
          text: "Ημερομηνία πρωτοκόλλου ΠΤΑΑ",
        },
        {
          value: "agreementProtocolNumber",
          text: "Αριθμός πρωτοκόλλου σύμφωνης γνώμης",
        },
        {
          value: "agreementProtocolNumberDate",
          text: "Ημερομηνία πρωτοκόλλου σύμφωνης γνώμης",
        },
        {
          value: "options",
          width: "120px",
          sortable: false,
        },
      ],
      accountEditForm: null,
      addAccountDialog: false,
    };
  },
  methods: {
    async getAccountsList() {
      this.accountsLoader = true;
      try {
        let res = await axios(
          `/api/pde/sub/job/account/list/paging/${this.$route.params.subJobId}`
        );
        this.accountsList = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.accountsLoader = false;
      }
    },
  },
  created() {
    this.getAccountsList();
  },
};
</script>

<style lang="scss" scoped></style>
